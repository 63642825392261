export default function apiInitializeHelper
  (
    url         = '',
    request     = '',
    action      = '',
    type        = {},
    data        = {},
    token       = false,
    contentType = false,
  ) {
  let headers = null
  
  if (token) {
    if (token) {
      headers = (contentType) ? {
        headers: {
          'Authorization': localStorage.getItem('token'),
          'content-type': contentType,
        },
      } : { headers: { 'Authorization': localStorage.getItem('token') } }
    }
  }
  
  const auth = headers
  
  return {
    url,
    request,
    action,
    type: {
      res: type.res || null,
      error: type.error || null,
    },
    data,
    token,
    auth,
  }
}
