import {COMPANY, COMPANY_ERROR} from '../types';
import MiddlewareAction from './MiddlewareAction';

export const getCompany = ( params={} ) => async dispatch => {

    console.log('*companyAction - params:', params);

    const action = 'companyAction';
    const serviceMiddleware = new MiddlewareAction({
        action  : action, 
        request : 'get',
        url     : `/api/v1/internal/company`, 
        type    : {res:COMPANY, error:COMPANY_ERROR}, 
        data    : params, 
        token   : true
    });

    console.log('*companyAction - serviceMiddleware:', serviceMiddleware);

    try{
        await serviceMiddleware.consumeService(false,'backend').then(()=>{ 
            const {data} = serviceMiddleware.apiResponse || {data:{}};
            console.log('*companyAction - try - else - data:', data);
            dispatch({ type: serviceMiddleware.apiResponse.type, payload: data });
        });
    }catch(e){ 
        console.log('*companyAction - catch - serviceMiddleware.apiResponse:', serviceMiddleware.apiResponse);
        dispatch({ type: serviceMiddleware.apiResponse.type, payload: {'error': {}}});
    }

}