import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

let CerrarSesion = withRouter(({ history }) => {
    const [componentLoaded, setComponentLoaded] = useState(true);

    useEffect(() => {}, []);

    useEffect(() => { 
        console.log('*CerrarSesion - useEffect - componentLoaded: ', componentLoaded); 
        componentInitialize();
    }, [componentLoaded]);

    const componentInitialize = () => {
        console.log('*CerrarSesion - componentInitialize - localStorage.getItem(myToken): ', localStorage.getItem('myToken'));
        localStorage.removeItem('myToken');
        history.push('/login');
    }

    return ( <div></div> );
});

export default CerrarSesion;