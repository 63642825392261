import {COMPANY, COMPANY_ERROR} from '../types';

const initialState = {
    company:[],
    loading:false
}

export default function(state = initialState, action){
    //console.log('companyReducer - action: ', action);
    switch(action.type){
        case COMPANY:
        return {
            ...state,
            company: action.payload,
            loading: true
        }
        case COMPANY_ERROR:
        return {
            ...state,
            company: action.payload,
            loading: false
        }
        default: return state
    }
}