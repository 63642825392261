import { LOGIN, LOGIN_ERROR } from '../types'
import MiddlewareAction from './MiddlewareAction'

export const setLogin = (
  params = {
    email: '',
    password: '',
    company_id: '',
  },
) => async dispatch => {
  const action = 'loginAction'
  
  const serviceMiddleware = new MiddlewareAction({
    action,
    request: 'POST',
    url: `/api/v1/internal/login`,
    type: {
      res: LOGIN,
      error: LOGIN_ERROR,
    },
    data: params,
    token: true,
  })
  
  try {
    await serviceMiddleware.consumeService(false, 'backend').then(() => {
      const { data } = serviceMiddleware.apiResponse || { data: {} }
  
      dispatch({
        type: serviceMiddleware.apiResponse.type,
        payload: data,
      })
    })
  } catch (e) {
    dispatch({
      type: serviceMiddleware.apiResponse.type,
      payload: { 'error': {} },
    })
  }
  
}
