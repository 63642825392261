import React, { useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { FormControl } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import { Field, reduxForm } from 'redux-form';
import { Button } from '@material-ui/core';

import validate from './helpers/validate';
import { connect } from 'react-redux';
import { getFinancesCreditSummary } from '../redux/actions/financesCreditSummaryAction';


import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

const useStyles = makeStyles({
    root: {
        width: 300,
    },
});

function valuetext(value) { return `${value}°C`; }

const renderTextField = ({	
  label,	
  input,	
  meta: { touched, invalid, error },	
  ...custom	
}) => (	
  <TextField	
    label={label}	
    placeholder={label}	
    error={touched && invalid}	
    helperText={touched && error}	
    {...input}	
    {...custom}	
  />	
);


let EstadoCuentaClientes = props => {
  const customClasses = useStyles();

  const [componentLoaded, setComponentLoaded] = useState(true);
  const [id, setId] = useState(true);
  const [name, setName] = useState(true);
  const [type, setType] = useState(true);
  const [label, setLabel] = useState(true);
  const [disabled, setDisabled] = useState(false);


  const [clientId, setClientId] = useState(null);
  const [message, setMessage] = useState('');
  //const [rows, setRows] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [balance, setBalance] = useState(0);
  const [limit, setLimit] = useState(0);
  const [used, setUsed] = useState(0);


  const { handleSubmit, pristine, reset, submitting, classes } = props;

  useEffect(() => {}, []);

  useEffect(() => { console.log('*Dashboard - useEffect - props - e: ', props); componentInitialize(); }, [props]);

  const componentInitialize = async e => { console.log('*Dashboard - componentInitialize - e: ', e); }


  useEffect(() => {
    console.log('*EstadoCuentaClientes - useEffect - props.financesCreditSummary: ', props.financesCreditSummary);
    if(typeof props.financesCreditSummary !== 'undefined'){
      
    console.log('*EstadoCuentaClientes - useEffect - props.financesCreditSummary.financesCreditSummary: ', props.financesCreditSummary.financesCreditSummary);
      const {error} = props.financesCreditSummary.financesCreditSummary || {error: false};
      //setRows((typeof props.financesCreditSummary !== 'undefined') ? props.financesCreditSummary.financesCreditSummary || [] : []);
      if(!error){ 
        //setRows(props.financesCreditSummary.financesCreditSummary.data || []); 

        const {credit_balance} = props.financesCreditSummary.financesCreditSummary.data || 0;
        const {credit_limit} = props.financesCreditSummary.financesCreditSummary.data || 0;
        const {credit_used} = props.financesCreditSummary.financesCreditSummary.data || 0;

        setBalance(credit_balance);
        setLimit(credit_limit);
        setUsed(credit_used);

        let total = (credit_used * 100) / credit_limit;
        console.log('***total::: ', total);
        if(isNaN(total)){
          total = 0;
        }else{
          total = (total < 100) ? total : 100;
        }

        setPercentage(total);
      }
      else{ 
        //setMessage('Se refrescará el token'); 
        //props.refreshLogin({ refresh_token: localStorage.getItem('refresh_token')}); 
      }
    }
  }, [props.financesCreditSummary]);

  const submitMethod = (e) => {
    console.log('*EstadoCuentaClientes - submitMethod - e: ', e);
    setClientId(e.client_id);
    props.getFinancesCreditSummary({ client_id: e.client_id });
  }


  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <p>Estado de Cuenta Clientes</p>

      <form noValidate autoComplete="off" onSubmit={handleSubmit(submitMethod)}>

        <FormControl>
          {/*
            <InputLabel htmlFor="my-input">ID Cliente</InputLabel>
            <Input id="my-input" aria-describedby="my-helper-text" />
            <FormHelperText id="my-helper-text">Requerido</FormHelperText>
          */}
          <Field name="client_id" label="*ID Cliente" type="number" 
            component={renderTextField} disabled={false} InputProps={{ inputProps: { min: 1, max: 9999999, maxLength: 7 } }} 
          />
        </FormControl>

        <Grid item xs={12} container className="">
          <Grid item xs={4} container className="">
            <Button className="form-button" variant="contained" color="secondary" type="submit"> 
              Uso de crédito
            </Button>
          </Grid>
          <Grid item xs={4} container className="">
            <Button className="form-button" variant="contained" color="secondary" type="submit"> 
              Descargar
            </Button>
          </Grid>
        </Grid>

      </form>


      <div className={customClasses.root}>
        
        <BorderLinearProgress variant="determinate" value={percentage} />
        <div class="BorderLinearProgressResponse">
          Utilizado: ${balance}
          / Saldo: ${limit}
          / Límite de crédito: ${used}
        </div>
      </div>

    </Container>
  );
}

//export default EstadoCuentaClientes;

EstadoCuentaClientes = reduxForm({
    form: 'EstadoCuentaClientesForm',
    validate,
    requiredFields : [],
    enableReinitialize: true
  })(EstadoCuentaClientes);

  const mapStateToProps = (state) => ({ financesCreditSummary: state.financesCreditSummary });
  const mapDispatchToProps = { getFinancesCreditSummary };

  export default connect(mapStateToProps, mapDispatchToProps)(EstadoCuentaClientes);