import { SALES_INVOICES, SALES_INVOICES_ERROR } from '../types'

const initialState = {
  salesInvoices: [],
  loading: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SALES_INVOICES:
      return {
        ...state,
        salesInvoices: action.payload,
        loading: true,
      }
    case SALES_INVOICES_ERROR:
      return {
        ...state,
        salesInvoices: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
