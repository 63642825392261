import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import { DataGrid } from '@material-ui/data-grid';
import { Button, Icon } from '@material-ui/core';
import { connect } from 'react-redux';
import { getFinancesComplements } from '../../redux/actions/financesComplementsAction';

const columns = [
    { field: 'cliente', headerName: 'Cliente', width: 125 },
    { field: 'fecha', headerName: 'Fecha', width: 125 },
    { field: 'factura', headerName: 'Factura', width: 125 },
    { field: 'folio', headerName: 'Folio-serie', width: 125 },
    { field: 'uuid', headerName: 'UUID', width: 125 },
    { field: 'modulo', headerName: 'Módulo', width: 125 },
    { field: 'pago', headerName: 'Pago', width: 125 },
    { field: 'saldo', headerName: 'Saldo', width: 125 },
    { field: 'total', headerName: 'Total', width: 125 },
    { field: 'vencimiento', headerName: 'Vencimiento', width: 125 },
    { field: 'botones', headerName: ' ', width: 125,  
      renderCell: (params) => { 
          console.log('*params: ', params);
          return(
            <Button
                className="button"
                variant="contained"
                color="primary"
                size="small"
                type="button"
            >
                <Icon>download</Icon> XML
            </Button>
          );
        } 
    }
  ];
  
  const rows = [
    /*
      { 
          id: 1,
          cliente: 1, 
          fecha: '15 marzo 2021', 
          factura: '123', 
          folio: 456,
          uuid: 159951, 
          modulo: 5, 
          pago: '$2,000,000', 
          saldo: '$3,000,000',
          total: '$1,000,000', 
          vencimiento: '123',
          
      }
    */
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//export default function Complementos(props) {
let Complementos = props => {

  const [open, setOpen] = React.useState(false);
  const [componentLoaded, setComponentLoaded] = React.useState(true);

  useEffect(() => {
    console.log('*modal - componentLoaded: ', componentLoaded);
    console.log('*modal - props: ', props);
  }, [componentLoaded]);

  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        <b>Complementos</b>
      </Button>
      <Dialog
        open={open}
        maxWidth={'lg'}
        fullWidth={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Complementos de Pago"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid item xs={12} container className="">
                <Grid item xs={12} container className="">
                    <Grid item xs={4} container className="">
                      <b>ID: </b>&nbsp;&nbsp; {props.parentProps.invoice_id || ''}
                    </Grid>
                    
                    <Grid item xs={4} container className="">
                      <b>Folio / Serie: </b>&nbsp;&nbsp; {props.parentProps.folios_serie || ''}
                    </Grid>

                    <Grid item xs={4} container className="">
                      <b>Módulo: </b>&nbsp;&nbsp; {props.parentProps.module || ''}
                    </Grid>
                </Grid>
                <Grid item xs={12} container className="">
                                    
                    <div style={{ height: 200, margin: '25px 0 0', width: '100%' }}>
                    <DataGrid rows={rows} columns={columns} pageSize={5} checkboxSelection />
                    </div>
                </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = { getFinancesComplements };
const mapStateToProps = (state) => ({ financesComplements: state.financesComplements });

export default connect(mapStateToProps, mapDispatchToProps)(Complementos);