import { Button, FormControl, Icon, InputLabel } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import { DataGrid } from '@material-ui/data-grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { refreshLogin } from '../redux/actions/loginRefreshAction'
import { getSalesInvoices } from '../redux/actions/salesInvoicesAction'
import DownloadPDFButton from './DownloadPDFButton'
import DatePicker from './helpers/datepicker'
import Complementos from './helpers/modal'
import validate from './helpers/validate'
import TextField from './TextField'
import { request } from '../services/api/request'

const columns = [
  {
    field: 'Cliente',
    headerName: 'Cliente',
    width: 225,
  },
  {
    field: 'Fecha',
    headerName: 'Fecha',
    width: 125,
  },
  {
    field: 'Factura',
    headerName: 'Factura',
    width: 150,
  },
  {
    field: 'FolioSerie',
    headerName: 'Folio-serie',
    width: 175,
  },
  {
    field: 'Uuid',
    headerName: 'UUID',
    width: 350,
  },
  {
    field: 'Modulo',
    headerName: 'Módulo',
    width: 125,
  },
  {
    field: 'Pago',
    headerName: 'Pago',
    width: 125,
  },
  {
    field: 'Saldo',
    headerName: 'Saldo',
    width: 125,
  },
  {
    field: 'Total',
    headerName: 'Total',
    width: 125,
  },
  {
    field: 'Vencimiento',
    headerName: 'Vencimiento',
    width: 125,
  },
  {
    field: 'botones',
    headerName: ' ',
    width: 200,
    renderCell: (params) => {
      const { Factura }    = params.data || ''
      const { FolioSerie } = params.data || ''
      const { Modulo }     = params.data || ''
      return (
        <Complementos
          parentProps={{
            invoice_id: Factura,
            folios_serie: FolioSerie,
            module: Modulo,
          }}
        />
      )
    },
  },
  {
    field: 'xml',
    headerName: ' ',
    width: 125,
    renderCell: (params) => {
      return (
        <Button
          className="button"
          variant="contained"
          color="primary"
          size="small"
          type="button"
        >
          <Icon>download</Icon> XML
        </Button>
      )
    },
  },
  {
    field: 'pdf',
    headerName: ' ',
    width: 125,
    renderCell: (params) => {
      return <DownloadPDFButton/>
    },
  },
]

let FacturaVentas = (props) => {
  const [searching, setSearching]           = useState(false)
  const [fromDate, setFromDate]             = useState('')
  const [toDate, setToDate]                 = useState('')
  const [rows, setRows]                     = useState([])
  const [message, setMessage]               = useState('')
  const [clients, setClients]               = useState([])
  const [selectedClient, setSelectedClient] = useState(null)
  
  const getAssignedClients = async() => {
    const response = await request.get('/sales/clients/assigned', {
      headers: {
        'Authorization': localStorage.getItem('token'),
        'Content-type': 'application/json',
      },
    })
    
    setClients(response)
  }
  
  useEffect(() => {
    getAssignedClients()
  }, [])
  
  useEffect(() => {
    const { error }            =
            typeof props.loginData !== 'undefined'
              ? props.loginData.login || { error: {} }
              : { error: {} }
    const { auth_data }        =
            typeof props.loginData !== 'undefined'
              ? props.loginData.login || { auth_data: {} }
              : { auth_data: {} }
    const { other_menu_links } =
            typeof props.loginData !== 'undefined'
              ? props.loginData.login || { other_menu_links: {} }
              : { other_menu_links: {} }
    const { user_data }        =
            typeof props.loginData !== 'undefined'
              ? props.loginData.login || { user_data: {} }
              : { user_data: {} }
    
    if (typeof error !== 'undefined') {
      setMessage(error.message || 'Error')
    }
    else {
      if (typeof auth_data !== 'undefined') {
        localStorage.setItem('expires_at', auth_data.expires_at || '')
        localStorage.setItem('refresh_token', auth_data.refresh_token || '')
        localStorage.setItem('token', auth_data.token || '')
      }
      
      if (typeof user_data !== 'undefined') {
        localStorage.setItem('user_data', user_data.permissions || [])
        
        setMessage('Bienvenido')
      }
    }
  }, [props.loginData])
  
  useEffect(() => {
    console.clear()
    console.log(props.salesInvoices)
    
    if (typeof props.salesInvoices !== 'undefined') {
      const { error } = props.salesInvoices.salesInvoices || {
        error: false,
      }
  
      console.log(props.salesInvoices)
      
      if (!error) {
        setRows(props.salesInvoices.salesInvoices || [])
      }
      else {
        setMessage('Se refrescará el token')
        
        props.refreshLogin({
          refresh_token: localStorage.getItem('refresh_token'),
        })
      }
    }
  }, [props.salesInvoices])
  
  const { handleSubmit } = props
  
  const onChangeDatePicker = (e, type = 'from') => {
    const year  = e.value.getFullYear() || '0000'
    const month = (e.value.getMonth() + 1 + '').padStart(2, '0')
    const day   = e.value.getDate()
    
    if (type === 'from') {
      setFromDate(`${year}-${month}-${day}`)
    }
    else {
      setToDate(`${year}-${month}-${day}`)
    }
  }
  
  const submitMethod = (e) => {
    props.getSalesInvoices({
      date_from: fromDate || '',
      date_to: toDate || '',
      client_id: selectedClient || 0,
      sales_order: e.sales_order || '',
      po_client: e.po_client || '',
      opportunity: e.opportunity || '',
    })
  }
  
  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline/>
      
      <p>Factura Ventas</p>
      
      <form noValidate autoComplete="off" onSubmit={handleSubmit(submitMethod)}>
        <Grid item xs={12} container className="">
          <Grid item xs={4} container className="">
            <DatePicker
              parentCallback={(e) => onChangeDatePicker(e)}
              parentProps={{
                props: { ...props },
                datePickerId: 1,
                datePickerName: 'fromDatepicker',
                datePickerType: true,
                datePickerLabel: '*Desde',
                datePickerDisabled: searching,
              }}
            />
          </Grid>
          
          <Grid item xs={4} container className="">
            <DatePicker
              parentCallback={(e) => onChangeDatePicker(e, 'to')}
              parentProps={{
                props: { ...props },
                datePickerId: 1,
                datePickerName: 'toDatepicker',
                datePickerType: true,
                datePickerLabel: '*Hasta',
                datePickerDisabled: searching,
              }}
            />
          </Grid>
          
          <Grid
            item
            xs={4}
            container
            className=""
            style={{ marginTop: '1.05rem' }}
          >
            <FormControl style={{ width: '80%' }}>
              <InputLabel htmlFor="client-id">Id Cliente</InputLabel>
              <Select
                labelId="client-id"
                id="demo-simple-select"
                value={selectedClient}
                onChange={(e) => setSelectedClient(e.target.value)}
                style={{ height: '3rem' }}
              >
                {clients && clients.map(
                  client => {
                    return <MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>
                  })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        
        <Grid item xs={12} container className="">
          <Grid item xs={4} container className="">
            <FormControl>
              <Field
                name="sales_order"
                label="Orden de venta"
                component={TextField}
                disabled={false}
              />
            </FormControl>
          </Grid>
          
          <Grid item xs={4} container className="">
            <FormControl>
              <Field
                name="po_client"
                label="Po Cliente"
                component={TextField}
                disabled={false}
              />
            </FormControl>
          </Grid>
          
          <Grid item xs={4} container className="">
            <FormControl>
              <Field
                name="opportunity"
                label="Oportunidad"
                component={TextField}
                disabled={false}
              />
            </FormControl>
          </Grid>
        </Grid>
        
        <Grid item xs={12} container className="">
          <Grid item xs={4} container className="">
            <Button
              className="form-button"
              variant="contained"
              color="secondary"
              type="submit"
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </form>
      
      <Grid item xs={12} container className="invoicesDatagrid">
        <DataGrid rows={rows}
                  columns={columns}
                  pageSize={100}
                  columnBuffer={1}
                  columnTypes="GridColumnTypesRecord"
                  headerHeight={56}/>
      </Grid>
      
      <Grid item xs={12} container className="invoicesDatagrid">
        {' '}
        {message}
        {' '}
      </Grid>
    </Container>
  )
}

FacturaVentas = reduxForm({
  form: 'FacturaVentasForm',
  validate,
  requiredFields: [],
  enableReinitialize: true,
})(FacturaVentas)

const mapDispatchToProps = {
  getSalesInvoices,
  refreshLogin,
}

const mapStateToProps = (state) => ({
  salesInvoices: state.salesInvoices,
  loginData: state.login,
})

export default connect(mapStateToProps, mapDispatchToProps)(FacturaVentas)
