import MaterialTextField from '@material-ui/core/TextField'
import React from 'react'

const TextField = ({
  label = '',
  id = '',
  input,
  meta: {
    touched,
    invalid,
    error,
  },
  ...custom
}) => (
  <MaterialTextField
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
)

export default TextField
