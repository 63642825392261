import { CLEAN_LOGIN, CLEAN_LOGIN_ERROR, LOGIN, LOGIN_ERROR, REFRESH_LOGIN, REFRESH_LOGIN_ERROR } from '../types'

const initialState = {
  login: [],
  loading: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        login: action.payload,
        loading: true,
      }
    case LOGIN_ERROR:
      return {
        ...state,
        login: action.payload,
        loading: false,
      }
    case REFRESH_LOGIN:
      return {
        ...state,
        login: action.payload,
        loading: true,
      }
    case REFRESH_LOGIN_ERROR:
      return {
        ...state,
        login: action.payload,
        loading: false,
      }
    case CLEAN_LOGIN:
      return {
        ...state,
        login: action.payload,
        loading: true,
      }
    case CLEAN_LOGIN_ERROR:
      return {
        ...state,
        login: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
