const options = [
  {
    'id': 1,
    'title': 'FINANZAS',
    'enabled': true,
    'options': [
      {
        'id': 1,
        'title': 'Facturas de clientes',
        'icon': 'web',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'daikin_invoices_list',
      },
      {
        'id': 2,
        'title': 'Estado de cuenta de clientes',
        'icon': 'dashboard',
        'enabled': true,
        'path': '/estado-cuenta-clientes',
        'role': 'daikin_client_statements_download',
      },
      {
        'id': 3,
        'title': 'Crédito cliente',
        'icon': 'chat',
        'enabled': true,
        'path': '/credito-cliente',
        'role': 'daikin_credit_view',
      },
    ],
  },
  {
    'id': 2,
    'title': 'VENTAS',
    'enabled': true,
    'options': [
      {
        'id': 1,
        'title': 'Facturas de clientes',
        'icon': 'show_chart',
        'enabled': true,
        'path': '/factura-ventas',
        'role': 'daikin_sales_invoices_list',
      },
      {
        'id': 2,
        'title': 'Estado de cuenta',
        'icon': 'equalizer',
        'enabled': true,
        'path': '/estado-cuenta-ventas',
        'role': 'daikin_sales_credit_view',
      },
    ],
  },
  {
    'id': 3,
    'title': 'GARANTÍAS',
    'enabled': true,
    'options': [
      {
        'id': 1,
        'title': 'Facturas de clientes',
        'icon': 'settings',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'daikin_warranties_invoices_list',
      },
    ],
  },
  {
    'id': 4,
    'title': 'CLIENTES',
    'enabled': true,
    'options': [
      {
        'id': 1,
        'title': 'Clientes',
        'icon': 'cloud',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'generic_clients_list',
      },
    ],
  },
  {
    'id': 5,
    'title': 'DOCUMENTOS',
    'enabled': true,
    'options': [
      {
        'id': 1,
        'title': 'Categorías',
        'icon': 'tab',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'generic_documents_categories_list',
      },
      {
        'id': 2,
        'title': 'Documentos',
        'icon': 'web',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'generic_documents_list',
      },
      {
        'id': 3,
        'title': 'Agregar documento',
        'icon': 'attachment',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'generic_documents_add',
      },
      {
        'id': 4,
        'title': 'Multiples documentos',
        'icon': 'attachment',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'generic_documents_add',
      },
    ],
  },
  {
    'id': 6,
    'title': 'USUARIOS',
    'enabled': true,
    'options': [
      {
        'id': 1,
        'title': 'Áreas',
        'icon': 'person',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'generic_areas_list',
      },
      {
        'id': 2,
        'title': 'Roles',
        'icon': 'shield',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'generic_roles_list',
      },
      {
        'id': 3,
        'title': 'Usuarios',
        'icon': 'search',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'generic_users_list',
      },
    ],
  },
  {
    'id': 6,
    'title': 'POLÍTICAS',
    'enabled': true,
    'options': [
      {
        'id': 1,
        'title': 'Usuarios Revisores',
        'icon': 'person',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'generic_procedure_users_edit',
      },
      {
        'id': 1,
        'title': 'Usuarios Autorizadores',
        'icon': 'person',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'generic_procedure_users_edit',
      },
      {
        'id': 1,
        'title': 'Usuarios Publicadores',
        'icon': 'person',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'generic_procedure_users_edit',
      },
      {
        'id': 2,
        'title': 'Revisar',
        'icon': 'search',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'generic_procedures_review',
      },
      {
        'id': 2,
        'title': 'Autorizar',
        'icon': 'search',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'generic_procedures_authorize',
      },
      {
        'id': 2,
        'title': 'Publicar',
        'icon': 'search',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'generic_procedures_publish',
      },
      {
        'id': 3,
        'title': 'Ver Status',
        'icon': 'web',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'generic_procedures_status',
      },
      {
        'id': 3,
        'title': 'Ver Documentos',
        'icon': 'web',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'generic_procedures_list',
      },
    ],
  },
  {
    'id': 6,
    'title': 'NOTICIAS',
    'enabled': true,
    'options': [
      {
        'id': 1,
        'title': 'Listas de noticias',
        'icon': 'dashboard',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'generic_internal_news_topics_list',
      },
      {
        'id': 1,
        'title': 'Noticias Internas',
        'icon': 'dashboard',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'generic_internal_news_list',
      },
      {
        'id': 1,
        'title': 'Noticias Clientes',
        'icon': 'dashboard',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'generic_internal_client_news_list',
      },
    ],
  },
  {
    'id': 6,
    'title': 'CALENDARIOS',
    'enabled': true,
    'options': [
      {
        'id': 1,
        'title': 'Calendarios',
        'icon': 'web',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'generic_calendars_list',
      },
      {
        'id': 1,
        'title': 'Eventos',
        'icon': 'web',
        'enabled': true,
        'path': '/factura-clientes',
        'role': 'generic_calendar_events_list',
      },
    ],
  },
  {
    'id': 10,
    'title': 'CUENTA',
    'enabled': true,
    'options': [
      {
        'id': 1,
        'title': 'Cerrar sesión',
        'icon': 'login',
        'enabled': true,
        'path': '/cerrar-sesion',
        'role': 'static',
      },
      {
        'id': 1,
        'title': 'Refrescar sesión',
        'icon': 'login',
        'enabled': true,
        'path': '/refrescar-sesion',
        'role': 'static',
      },
    ],
  },
]

export default options
