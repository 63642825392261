import {FINANCES_DOWNLOAD_PDF, FINANCES_DOWNLOAD_PDF_ERROR} from '../types';

const initialState = {
    financesDownloadPDF:[],
    loading:false
}

export default function(state = initialState, action){
    //console.log('financesDownloadPDFReducer - action: ', action);
    switch(action.type){
        case FINANCES_DOWNLOAD_PDF:
        return {
            ...state,
            financesDownloadPDF: action.payload,
            loading: true
        }
        case FINANCES_DOWNLOAD_PDF_ERROR:
        return {
            ...state,
            financesDownloadPDF: action.payload,
            loading: false
        }
        default: return state
    }
}