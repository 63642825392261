import {FINANCES_CREDIT_SUMMARY, FINANCES_CREDIT_SUMMARY_ERROR} from '../types';
import MiddlewareAction from './MiddlewareAction';

export const getFinancesCreditSummary = ( params={ client_id: 0 } ) => async dispatch => {

    console.log('*financesCreditSummaryAction - params:', params);

    const action = 'financesCreditSummaryAction';
    const serviceMiddleware = new MiddlewareAction({
        action  : action, 
        request : 'get',
        url     : `/api/v1/internal/daikin/finances/clients/${params.client_id}/credit/summary`, 
        type    : {res:FINANCES_CREDIT_SUMMARY, error:FINANCES_CREDIT_SUMMARY_ERROR}, 
        data    : params, 
        token   : true
    });

    console.log('*financesCreditSummaryAction - serviceMiddleware:', serviceMiddleware);

    try{
        await serviceMiddleware.consumeService(false,'backend').then(()=>{ 
            const {data} = serviceMiddleware.apiResponse || {data:[]};
            //data.data.forEach((element, index) => { console.log('index::: ', index); element['id'] = index; });
            console.log('*financesCreditSummaryAction - try - else - data:', data);
            dispatch({ type: serviceMiddleware.apiResponse.type, payload: data });
        });
    }catch(e){ 
        console.log('*financesCreditSummaryAction - catch - serviceMiddleware.apiResponse:', serviceMiddleware.apiResponse);
        dispatch({ type: serviceMiddleware.apiResponse.type, payload: {'error': {}}});
    }

}