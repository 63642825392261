export default function apiResponseHelper (params) {
  const log      = params.log || ''
  const type     = params.type || {}
  const data     = params.data || {}
  const request  = params.request || ''
  const response = params.response || {}
  const headers  = response.headers || {}
  
  return {
    'type': type,
    'data': data,
  }
}
