// TODO: Change this to object export.
export default function defaultApiInitialize () {
  return {
    url: '',
    request: '',
    action: '',
    type: {
      res: null,
      error: null,
    },
    data: {},
    token: false,
    auth: null,
  }
}
