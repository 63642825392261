import {GET_MENU, MENU_ERROR, SET_MENU, SET_MENU_ERROR, CLEAN_MENU, CLEAN_MENU_ERROR} from '../types';

const initialState = {
    menu:[],
    loading:false
}

export default function(state = initialState, action){
    //console.log('menuReducer - action: ', action);
    switch(action.type){
        case GET_MENU:
        return {
            ...state,
            menu: action.payload,
            loading: true
        }
        case MENU_ERROR:
        return {
            ...state,
            menu: action.payload,
            loading: false
        }
        case SET_MENU:
        return {
            ...state,
            menu: action.payload,
            loading: true
        }
        case SET_MENU_ERROR:
        return {
            ...state,
            menu: action.payload,
            loading: false
        }
        case CLEAN_MENU:
        return{
            ...state,
            menu: action.payload,
            loading: true
        }
        case CLEAN_MENU_ERROR:
        return{
            ...state,
            menu: action.payload,
            loading: false
        }
        default: return state
    }
}