import React, { useState } from 'react'
import Menu from '../Menu'

const Header = () => {
  const [menuStatus, setMenuStatus] = useState('closed')
  
  const closeMenu = () => { setMenuStatus('closed') }
  const openMenu  = () => { setMenuStatus('opened') }
  
  return (
    <header>
      <div className="navbar-fixed">
        <nav className="navbar white">
          <div className="nav-wrapper">
            <a href="#!"
               className="brand-logo grey-text text-darken-4">MEG CRM</a>
            
            <ul id="nav-mobile"
                className="right">
              <li key={0}
                  className="hide-on-med-and-down">
                <a href="#!"
                   data-target="dropdown1"
                   className="dropdown-trigger waves-effect">
                  <i className="material-icons">notifications</i>
                </a>
              </li>
              <li key={1}>
                <a href="#!"
                   data-target="chat-dropdown"
                   className="dropdown-trigger waves-effect">
                  <i className="material-icons">settings</i>
                </a>
              </li>
            </ul>
            
            <a href="#!"
               data-target="sidenav-left"
               className="sidenav-trigger left"
               onClick={openMenu}>
              <i className="material-icons black-text">menu</i>
            </a>
          </div>
        </nav>
      </div>
      
      <Menu closeMenu={closeMenu} menuStatus={menuStatus}/>
      
      <div id="dropdown1" className="dropdown-content notifications">
        <div className="notifications-title">notifications</div>
        <div className="card">
          <div className="card-content">
            <span className="card-title">Joe Smith made a purchase</span>
            <p>Content</p>
          </div>
          <div className="card-action">
            <a href="#!">view</a>
            <a href="#!">dismiss</a>
          </div>
        </div>
        <div className="card">
          <div className="card-content">
            <span className="card-title">Daily Traffic Update</span>
            <p>Content</p>
          </div>
          <div className="card-action">
            <a href="#!">view</a>
            <a href="#!">dismiss</a>
          </div>
        </div>
        <div className="card">
          <div className="card-content">
            <span className="card-title">New User Joined</span>
            <p>Content</p>
          </div>
          <div className="card-action">
            <a href="#!">view</a>
            <a href="#!">dismiss</a>
          </div>
        </div>
      </div>
      
      <div id="chat-dropdown" className="dropdown-content dropdown-tabbed">
        <ul className="tabs tabs-fixed-width">
          <li key={4} className="tab col s3"><a
            href="#settings">Settings</a></li>
          <li key={5} className="tab col s3"><a href="#friends"
                                                className="active">Friends</a>
          </li>
        </ul>
        <div id="settings" className="col s12">
          <div className="settings-group">
            <div className="setting">Night Mode
              <div className="switch right">
                <label>
                  <input type="checkbox"/>
                  <span className="lever"/>
                </label>
              </div>
            </div>
            <div className="setting">Beta Testing
              <label className="right">
                <input type="checkbox"/>
                <span/>
              </label>
            </div>
          </div>
        </div>
        <div id="friends" className="col s12">
          <ul className="collection flush">
            <li key={6} className="collection-item avatar">
              <div className="badged-circle online"/>
              <span className="title">Jane Doe</span>
              <p className="truncate">Lo-fi you probably haven't heard of
                                      them</p>
            </li>
            <li key={7} className="collection-item avatar">
              <div className="badged-circle"/>
              <span className="title">John Chang</span>
              <p className="truncate">etsy leggings raclette kickstarter
                                      four dollar toast</p>
            </li>
            <li key={8} className="collection-item avatar">
              <div className="badged-circle"/>
              <span className="title">Lisa Simpson</span>
              <p className="truncate">Raw denim fingerstache food truck chia
                                      health goth synth</p>
            </li>
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header
