//DownloadPDFButton
import React, { useEffect } from 'react';
import { Button, Icon } from '@material-ui/core';
import { connect } from 'react-redux';
import { getFinancesDownloadPDF } from '../redux/actions/financesDownloadPDFAction';

let DownloadPDFButton = props => {

    console.log('*DownloadPDFButton - props: ', props);

    
  useEffect(() => {
    console.log('*DownloadPDFButton - useEffect - props.financesDownloadPDF: ', props.financesDownloadPDF);
    
    if(typeof props.financesDownloadPDF !== 'undefined'){
        const {error} = props.financesDownloadPDF.financesDownloadPDF || {error: false};
        if(!error){
            const {financesDownloadPDF} = props.financesDownloadPDF || {financesDownloadPDF: ''};
            console.log('*data: ', financesDownloadPDF);
            window.open(financesDownloadPDF);
        }
        //setRows((typeof props.financesDownloadPDF !== 'undefined') ? props.financesDownloadPDF.financesDownloadPDF || [] : []);
        /*
        if(!error){ setRows(props.financesDownloadPDF.financesDownloadPDF || []); }
        else{ 
          setMessage('Se refrescará el token'); 
          props.refreshLogin({ refresh_token: localStorage.getItem('refresh_token')}); 
        }*/
      }
  }, [props.financesDownloadPDF]);

    const downloadPDF = (e) => { 
        console.log('HERE°!!!');
        props.getFinancesDownloadPDF();
    }

    return(
        <Button
            className="button"
            variant="contained"
            color="primary"
            size="small"
            type="button"
            onClick={downloadPDF}
        >
            <Icon>picture_as_pdf</Icon> PDF here
        </Button>
    );
}

const mapDispatchToProps = { getFinancesDownloadPDF };
const mapStateToProps = (state) => ({ financesDownloadPDF: state.financesDownloadPDF });

export default connect(mapStateToProps, mapDispatchToProps)(DownloadPDFButton);