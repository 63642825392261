import apiHelper from '../../helpers/apiHelper'
import apiBackendHelper from '../../helpers/apiBackendHelper'
import ServiceAction from './ServiceAction'

class MiddlewareAction extends ServiceAction {
  constructor (props) {
    super(props)
  }
  
  async consumeService (noRefresh = false, from = 'noWhere') {
    if (typeof this.props === 'undefined') return false
    
    this.setApiInitialize()
    
    try {
      const requestType = this.props.request.toUpperCase()
      
      if (requestType === 'GET') {
        if (from === 'backend') {
          this.response = await apiBackendHelper.get(this.apiInitialize.url, this.apiInitialize.auth)
        }
        else {
          this.response = await apiHelper.get(this.apiInitialize.url, this.apiInitialize.auth)
        }
      }
      
      if (requestType === 'POST') {
        if (from === 'backend') {
          this.response = await apiBackendHelper.post(this.apiInitialize.url, this.apiInitialize.data)
        }
        else {
          this.response = await apiHelper.post(this.apiInitialize.url, this.apiInitialize.data, this.apiInitialize.auth)
        }
      }
      
      if (requestType === 'PUT') {
        this.response = await apiHelper.put(this.apiInitialize.url, this.apiInitialize.data, this.apiInitialize.auth)
      }
      
      this.setTry()
    } catch (e) {
      this.response = e.response || {}
      
      let errorContext = (typeof this.response.data !== 'undefined') ? this.response.data.error : ''
      
      if (!noRefresh && (typeof errorContext.context !== 'undefined')) {
        if (errorContext.context === 'invalid_token') {}
      }
      
      this.setCatch()
    }
    
    this.setApiResponse()
  }
}

export default MiddlewareAction
