import React from 'react';
import ReactDOM from 'react-dom';
import './assets/index.css';
import App from './components/App';
import reportWebVitals from './assets/reportWebVitals';

import Store from './redux/store';
import { Provider } from 'react-redux';

window.$api        = { activity: { type: null, server: null, local: null, log: null } }
window.$token      = { expires_at: null, token: null, refreshToken: null, tokenMessage: null }
window.$location   = { href: null, origin: null, pathname: null }

window.$user = {
  auth_data: {},
  other_menu_links: {},
  user_data: {}
}

ReactDOM.render(
  <Provider store={Store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
