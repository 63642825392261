import React, { useState } from 'react'

let Navigation = props => {
  const [isOnline, setIsOnline] = useState(null)
  
  if (localStorage.getItem('myToken') === null) {
    props.push('/login')
  }
  else {
    props.push('/dashboard')
  }
  
  return isOnline
}

export default Navigation
