import {FINANCES_CREDIT_DETAILS, FINANCES_CREDIT_DETAILS_ERROR} from '../types';

const initialState = {
    financesCreditDetails:[],
    loading:false
}

export default function(state = initialState, action){
    //console.log('financesCreditDetailsReducer - action: ', action);
    switch(action.type){
        case FINANCES_CREDIT_DETAILS:
        return {
            ...state,
            financesCreditDetails: action.payload,
            loading: true
        }
        case FINANCES_CREDIT_DETAILS_ERROR:
        return {
            ...state,
            financesCreditDetails: action.payload,
            loading: false
        }
        default: return state
    }
}