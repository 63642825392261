import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Navigation from './hooks/Navigation';

const Layout = withRouter(({ history }) => {    
  console.log('*history: ', history);
  Navigation(history);

  const [componentLoaded, setComponentLoaded] = useState(true);
  useEffect(()=>{ console.log('*componentLoaded: ', componentLoaded); }, [componentLoaded]);

  return(
    <div onClick={() => history.push('/path/some/where')}>
        some clickable element
    </div>
  ); 
});

export default Layout;