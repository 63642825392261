import {REFRESH_LOGIN, REFRESH_LOGIN_ERROR} from '../types';
import MiddlewareAction from './MiddlewareAction';

export const refreshLogin = ( params={refresh_token:''}) => async dispatch => {

    console.log('*loginRefreshAction - params:', params);

    const action = 'loginRefreshAction';
    const serviceMiddleware = new MiddlewareAction({
        action  : action, 
        request : 'post',
        url     : `/api/v1/internal/login/refresh`, 
        type    : {res:REFRESH_LOGIN, error:REFRESH_LOGIN_ERROR}, 
        data    : params, 
        token   : true
    });    
    console.log('*loginRefreshAction - serviceMiddleware:', serviceMiddleware);

    try{
        await serviceMiddleware.consumeService(false,'backend').then(()=>{ 
            const {data} = serviceMiddleware.apiResponse || {data:{}};
            console.log('*loginRefreshAction - try - else - data:', data);
            dispatch({ type: serviceMiddleware.apiResponse.type, payload: data });
        });
    }catch(e){ 
        console.log('*loginRefreshAction - catch - serviceMiddleware.apiResponse:', serviceMiddleware.apiResponse);
        dispatch({ type: serviceMiddleware.apiResponse.type, payload: {'error': {}}});
    }

}