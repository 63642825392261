import React, { useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { FormControl } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import { Field, reduxForm } from 'redux-form';
import { Button } from '@material-ui/core';

import { DataGrid } from '@material-ui/data-grid';

import validate from './helpers/validate';
import { connect } from 'react-redux';
import { getFinancesCreditDetails } from '../redux/actions/financesCreditDetailsAction';

const renderTextField = ({	
  label,	
  input,	
  meta: { touched, invalid, error },	
  ...custom	
}) => (	
  <TextField	
    label={label}	
    placeholder={label}	
    error={touched && invalid}	
    helperText={touched && error}	
    {...input}	
    {...custom}	
  />	
);

const columns = [
  { field: 'Categoria', headerName: 'Categoría', width: 225 },
  { field: 'FolioSerie', headerName: 'Folio-serie', width: 125 },
  { field: 'Comprometido', headerName: 'Comprometido', width: 150 },
  { field: 'Pagado', headerName: 'Pagado', width: 175 },
  { field: 'Saldo', headerName: 'Saldo', width: 350 },
  { field: 'Fecha', headerName: 'Fecha Factura', width: 125 },
  { field: 'Vencimiento', headerName: 'Fecha Vencimiento', width: 125 },
];


let CreditoCliente = props => {

  const [componentLoaded, setComponentLoaded] = useState(true);
  const [id, setId] = useState(true);
  const [name, setName] = useState(true);
  const [type, setType] = useState(true);
  const [label, setLabel] = useState(true);
  const [disabled, setDisabled] = useState(false);


  const [clientId, setClientId] = useState(null);
  const [message, setMessage] = useState('');
  const [rows, setRows] = useState([]);


  const { handleSubmit, pristine, reset, submitting, classes } = props;

  useEffect(() => {}, []);

  useEffect(() => { console.log('*Dashboard - useEffect - props - e: ', props); componentInitialize(); }, [props]);

  const componentInitialize = async e => { console.log('*Dashboard - componentInitialize - e: ', e); }

  useEffect(()=>{ console.log('*CreditoClientes - useEffect - rows: ', rows); }, [rows]);

  useEffect(() => {
    console.log('*CreditoClientes - useEffect - props.financesCreditDetails: ', props.financesCreditDetails);
    if(typeof props.financesCreditDetails !== 'undefined'){
      
    console.log('*CreditoClientes - useEffect - props.financesCreditDetails.financesCreditDetails: ', props.financesCreditDetails.financesCreditDetails);
      const {error} = props.financesCreditDetails.financesCreditDetails || {error: false};
      //setRows((typeof props.financesCreditDetails !== 'undefined') ? props.financesCreditDetails.financesCreditDetails || [] : []);
      if(!error){ setRows(props.financesCreditDetails.financesCreditDetails.data || []); }
      else{ 
        //setMessage('Se refrescará el token'); 
        //props.refreshLogin({ refresh_token: localStorage.getItem('refresh_token')}); 
      }
    }
  }, [props.financesCreditDetails]);

  const submitMethod = (e) => {
    console.log('*CreditoClientes - submitMethod - e: ', e);
    setClientId(e.client_id);
    props.getFinancesCreditDetails({ client_id: e.client_id });
  }

  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <p>Crédito Cliente</p>

      <form noValidate autoComplete="off" onSubmit={handleSubmit(submitMethod)}>
        <FormControl>
          {/*
            <InputLabel htmlFor="my-input">Cliente</InputLabel>
            <Input id="my-input" aria-describedby="my-helper-text" />
            <FormHelperText id="my-helper-text">Requerido</FormHelperText>
          */}
          <Field name="client_id" label="*ID Cliente" type="number" 
            component={renderTextField} disabled={false} InputProps={{ inputProps: { min: 1, max: 9999999, maxLength: 7 } }} 
          />
        </FormControl>

        <Grid item xs={12} container className="">
          <Grid item xs={4} container className="">
            <Button className="form-button" variant="contained" color="secondary" type="submit"> Buscar </Button>
          </Grid>
        </Grid>
      </form>

      <Grid item xs={12} container className="invoicesDatagrid">
        <DataGrid rows={rows} columns={columns} pageSize={100} />
      </Grid>

      <Grid item xs={12} container className="invoicesDatagrid"> {message} </Grid>
    </Container>
  );
}

//export default CreditoCliente;

CreditoCliente = reduxForm({
    form: 'CreditoClienteForm',
    validate,
    requiredFields : [],
    enableReinitialize: true
  })(CreditoCliente);

  const mapStateToProps = (state) => ({ financesCreditDetails: state.financesCreditDetails });
  const mapDispatchToProps = { getFinancesCreditDetails };
  
  export default connect(mapStateToProps, mapDispatchToProps)(CreditoCliente);