import axios from 'axios'
import { API_BASE_URL } from '../../constants'

export async function request(endpoint, { body, ...customConfig } = {}) {
  const headers = { 'Content-Type': 'application/json' }
  const method = body ? 'POST' : 'GET'
  
  const config = {
    method,
    API_BASE_URL,
    headers,
    data: body,
    ...customConfig,
  }
  
  let data
  
  try {
    const response = await axios(`${API_BASE_URL}${endpoint}`, config)
  
    console.log(response)
    
    if (response.status >= 200 && response.status <= 300) {
      return response.data
    }
    
    throw Error(response.statusText)
  } catch (err) {
    return Promise.reject(err.message ? err.message : data)
  }
}

request.get = function (endpoint, config = {}) {
  return request(endpoint, {
    ...config,
    method: 'GET',
  })
}

request.post = function (endpoint, body, config = {}) {
  return request(endpoint, {
    ...config,
    body,
  })
}
