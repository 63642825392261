import thunk from 'redux-thunk'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import rootReducer from './reducers'

const initialState = {}

const middleware = [thunk]

const Store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default Store
