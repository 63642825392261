import {GET_ROLES, ROLES_ERROR, SET_ROLES, SET_ROLES_ERROR, CLEAN_ROLES, CLEAN_ROLES_ERROR} from '../types';

const initialState = {
    roles:[],
    loading:false
}

export default function(state = initialState, action){
    //console.log('rolesReducer - action: ', action);
    switch(action.type){
        case GET_ROLES:
        return {
            ...state,
            roles: action.payload,
            loading: true
        }
        case ROLES_ERROR:
        return {
            ...state,
            roles: action.payload,
            loading: false
        }
        case SET_ROLES:
        return {
            ...state,
            roles: action.payload,
            loading: true
        }
        case SET_ROLES_ERROR:
        return {
            ...state,
            roles: action.payload,
            loading: false
        }
        case CLEAN_ROLES:
        return{
            ...state,
            roles: action.payload,
            loading: true
        }
        case CLEAN_ROLES_ERROR:
        return{
            ...state,
            roles: action.payload,
            loading: false
        }
        default: return state
    }
}