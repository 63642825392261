import { ACTION_DEFAULT, ACTION_DEFAULT_ERROR } from '../types'
import apiResponseHelper from '../../helpers/apiResponseHelper'
import apiInitializeHelper from '../../helpers/apiInitializeHelper'
import defaultApiInitialize from '../../helpers/defaultApiInitialize'

class ServiceAction {
  constructor (props) {
    this.props = props
    
    this.refreshToken = null
    
    this.apiResponse   = null
    this.apiInitialize = false
    
    this.type     = null
    this.data     = null
    this.status   = null
    this.response = null
    this.request  = null
    this.action   = null
    this.token    = null
    this.url      = null
    
    this.contentType = null
  }
  
  setTry () { this.type = this.apiInitialize.type.res || ACTION_DEFAULT }
  
  setCatch () { this.type = this.apiInitialize.type.error || ACTION_DEFAULT_ERROR }
  
  setApiInitialize () {
    this.apiInitialize = apiInitializeHelper(
      this.props.url || null,
      this.props.request || null,
      this.props.action || null,
      this.props.type || {},
      this.props.data || {},
      this.props.token || false,
      this.props.contentType || false,
    ) || defaultApiInitialize()
  
    console.log(this.apiInitialize)
  }
  
  setApiResponse () {
    this.data = this.response.data || []
    
    this.apiResponse = apiResponseHelper({
      response: this.response,
      request: this.apiInitialize.request,
      log: this.apiInitialize.action + ': ' + this.status,
      type: this.type,
      data: this.data || {},
    })
  }
  
}

export default ServiceAction
