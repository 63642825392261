import { SALES_INVOICES, SALES_INVOICES_ERROR } from '../types'
import { API_BASE_URL } from '../constants'
import MiddlewareAction from './MiddlewareAction'

const salesUrl   = '/sales/invoices'

export const getSalesInvoices = (params = {
  date_from: '',
  date_to: '',
  client_id: 0,
  sales_order: '',
  po_client: '',
  opportunity: '',
}) => async dispatch => {
  let extra_params = ''
  
  if (params.sales_order !== '') { extra_params += '&sales_order=' + params.sales_order }
  if (params.po_client !== '') { extra_params += '&po_client=' + params.po_client }
  if (params.opportunity !== '') { extra_params += '&oportunity=' + params.opportunity }
  
  const action = 'financesInvoicesAction'
  
  const serviceMiddleware = new MiddlewareAction({
    action,
    request: 'GET',
    url: `${API_BASE_URL}${salesUrl}?date_from=${params.date_from}&date_to=${params.date_to}&client_id=${params.client_id}${extra_params}
    `,
    type: {
      res: SALES_INVOICES,
      error: SALES_INVOICES_ERROR,
    },
    data: params,
    token: true,
  })
  
  try {
    await serviceMiddleware.consumeService(false, 'backend').then(() => {
      const { data } = serviceMiddleware.apiResponse || { data: [] }
      
      data.forEach(element => element['id'] = element.Uuid || 0)
      
      dispatch({
        type: serviceMiddleware.apiResponse.type,
        payload: data,
      })
    })
  } catch (e) {
    console.error({ e })
    
    dispatch({
      type: serviceMiddleware.apiResponse.type,
      payload: { 'error': {} },
    })
  }
}
