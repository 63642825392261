import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import DatePicker from "./helpers/datepicker";
import { FormControl } from "@material-ui/core";

import { Field, reduxForm } from "redux-form";
import TextField from "@material-ui/core/TextField";
import { Button, Icon } from "@material-ui/core";

import validate from "./helpers/validate";
import { connect } from "react-redux";

import { DataGrid } from "@material-ui/data-grid";
import Complementos from "./helpers/modal";

import { refreshLogin } from "../redux/actions/loginRefreshAction";
import { getFinancesInvoices } from "../redux/actions/financesInvoicesAction";
import DownloadPDFButton from "./DownloadPDFButton";

const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

const columns = [
  { field: "Cliente", headerName: "Cliente", width: 225 },
  { field: "Fecha", headerName: "Fecha", width: 125 },
  { field: "Factura", headerName: "Factura", width: 150 },
  { field: "FolioSerie", headerName: "Folio-serie", width: 175 },
  { field: "Uuid", headerName: "UUID", width: 350 },
  { field: "Modulo", headerName: "Módulo", width: 125 },
  { field: "Pago", headerName: "Pago", width: 125 },
  { field: "Saldo", headerName: "Saldo", width: 125 },
  { field: "Total", headerName: "Total", width: 125 },
  { field: "Vencimiento", headerName: "Vencimiento", width: 125 },
  {
    field: "botones",
    headerName: " ",
    width: 200,
    renderCell: (params) => {
      console.log("*botones - params: ", params);
      const { Factura } = params.data || "";
      const { FolioSerie } = params.data || "";
      const { Modulo } = params.data || "";
      return (
        <Complementos
          parentProps={{
            invoice_id: Factura,
            folios_serie: FolioSerie,
            module: Modulo,
          }}
        />
      );
    },
  },
  {
    field: "xml",
    headerName: " ",
    width: 125,
    renderCell: (params) => {
      console.log("*params: ", params);
      return (
        <Button
          className="button"
          variant="contained"
          color="primary"
          size="small"
          type="button"
        >
          <Icon>download</Icon> XML
        </Button>
      );
    },
  },
  {
    field: "pdf",
    headerName: " ",
    width: 125,
    renderCell: (params) => {
      console.log("*params: ", params);
      return <DownloadPDFButton />;
    },
  },
];

/*
const rows = [
    {
        id: 1,
        cliente: 1,
        fecha: '15 marzo 2021',
        factura: '123',
        folio: 456,
        uuid: 159951,
        modulo: 5,
        pago: '$2,000,000',
        saldo: '$3,000,000',
        total: '$1,000,000',
        vencimiento: '123',
        
    }
];*/

let FacturaClientes = (props) => {
  const [componentLoaded, setComponentLoaded] = useState(true);
  const [id, setId] = useState("no-id");
  const [name, setName] = useState("no-name");
  const [type, setType] = useState(true);
  const [label, setLabel] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [rows, setRows] = useState([]);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [clientId, setClientId] = useState(null);
  const [salesOrder, setSalesOrder] = useState(null);
  const [poClient, setPoClient] = useState(null);
  const [oportunity, setOportunity] = useState(null);

  const [message, setMessage] = useState("");

  const { handleSubmit, pristine, reset, submitting, classes } = props;

  useEffect(() => {}, []);

  useEffect(() => {
    console.log(
      "*FacturaClientes - useEffect - componentLoaded: ",
      componentLoaded
    );
    //props.getFinancesInvoices();
    console.log("*FacturasClientes - window.$token: ", window.$token);
  }, [componentLoaded]);

  useEffect(() => {
    console.log("*FacturaClientes - useEffect - rows: ", rows);
  }, [rows]);

  useEffect(() => {
    console.log(
      "*FacturaClientes - useEffect - props.financesInvoices: ",
      props.financesInvoices
    );
    if (typeof props.financesInvoices !== "undefined") {
      const { error } = props.financesInvoices.financesInvoices || {
        error: false,
      };
      //setRows((typeof props.financesInvoices !== 'undefined') ? props.financesInvoices.financesInvoices || [] : []);
      if (!error) {
        setRows(props.financesInvoices.financesInvoices || []);
      } else {
        setMessage("Se refrescará el token");
        props.refreshLogin({
          refresh_token: localStorage.getItem("refresh_token"),
        });
      }
    }
  }, [props.financesInvoices]);

  useEffect(() => {
    console.log(
      "*FacturasClientes - useEffect - props.loginData: ",
      props.loginData
    );

    const { error } =
      typeof props.loginData !== "undefined"
        ? props.loginData.login || { error: {} }
        : { error: {} };
    const { auth_data } =
      typeof props.loginData !== "undefined"
        ? props.loginData.login || { auth_data: {} }
        : { auth_data: {} };
    const { other_menu_links } =
      typeof props.loginData !== "undefined"
        ? props.loginData.login || { other_menu_links: {} }
        : { other_menu_links: {} };
    const { user_data } =
      typeof props.loginData !== "undefined"
        ? props.loginData.login || { user_data: {} }
        : { user_data: {} };

    if (typeof error !== "undefined") {
      setMessage(error.message || "Error");
      //setVariant('determinate');
    } else {
      if (typeof auth_data !== "undefined") {
        /*localStorage.setItem('auth_data', auth_data);*/
        localStorage.setItem("expires_at", auth_data.expires_at || "");
        localStorage.setItem("refresh_token", auth_data.refresh_token || "");
        localStorage.setItem("token", auth_data.token || "");
      }
      
      if (typeof other_menu_links !== "undefined") {
        /*localStorage.setItem('other_menu_links', other_menu_links);*/
      }
      
      if (typeof user_data !== "undefined") {
        localStorage.setItem("user_data", user_data.permissions || []);
        //localStorage.setItem('myToken', "123");
        
        setMessage("Bienvenido");
        
        props.getFinancesInvoices({
          date_from: fromDate || "",
          date_to: toDate || "",
          client_id: clientId || 0,
          sales_order: salesOrder || "",
          po_client: poClient || "",
          oportunity: oportunity || "",
        });
      }
    }
  }, [props.loginData]);

  useEffect(() => {
    console.log("*Dashboard - useEffect - props - e: ", props);
    componentInitialize();
  }, [props]);

  const componentInitialize = async (e) => {
    console.log("*Dashboard - componentInitialize - e: ", e);
  };

  const fromDatePickerCallback = (e) => {
    console.log("*fromDatePickerCallback - e: ", e);
    console.log("getFullYear: ", e.value.getFullYear());
    console.log("getMonth() + 1: ", e.value.getMonth() + 1);
    console.log("getDate: ", e.value.getDate());
    const year = e.value.getFullYear() || "0000";
    let month = e.value.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    const day = e.value.getDate();
    setFromDate(year + "-" + month + "-" + day);
  };
  const toDatePickerCallback = (e) => {
    console.log("*toDatePickerCallback - e: ", e);
    //setToDate(e);
    const year = e.value.getFullYear() || "0000";
    let month = e.value.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    const day = e.value.getDate();
    setToDate(year + "-" + month + "-" + day);
  };

  const submitMethod = (e) => {
    console.log("*FacturaClientes - submitMethod - e: ", e);
    console.log("*FacturaClientes - submitMethod - toDate: ", toDate);
    console.log("*FacturaClientes - submitMethod - fromDate: ", fromDate);
    setClientId(e.client_id);
    setSalesOrder(e.sales_order);
    setPoClient(e.po_client);
    setOportunity(e.oportunity);
    props.getFinancesInvoices({
      date_from: fromDate || "",
      date_to: toDate || "",
      client_id: e.client_id || 0,
      sales_order: e.sales_order || "",
      po_client: e.po_client || "",
      oportunity: e.oportunity || "",
    });
  };

  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <p>Factura Clientes</p>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(submitMethod)}>
        <Grid item xs={12} container className="">
          <Grid item xs={4} container className="">
            <DatePicker
              parentCallback={fromDatePickerCallback}
              parentProps={{
                props: props,
                datePickerId: id,
                datePickerName: name,
                datePickerType: type,
                datePickerLabel: "*Desde",
                datePickerDisabled: disabled,
              }}
            />
          </Grid>
          <Grid item xs={4} container className="">
            <DatePicker
              parentCallback={toDatePickerCallback}
              parentProps={{
                props: props,
                datePickerId: id,
                datePickerName: name,
                datePickerType: type,
                datePickerLabel: "*Hasta",
                datePickerDisabled: disabled,
              }}
            />
          </Grid>
          <Grid
            item
            xs={4}
            container
            className=""
            style={{
              marginTop: "1.1rem",
            }}
          >
            <FormControl>
              <Field
                name="client_id"
                label="*ID Cliente"
                type="number"
                component={renderTextField}
                disabled={false}
                InputProps={{
                  inputProps: { min: 1, max: 9999999, maxLength: 7 },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12} container className="">
          <Grid item xs={4} container className="">
            <FormControl>
              <Field
                name="sales_order"
                label="Orden de venta"
                component={renderTextField}
                disabled={false}
              />
            </FormControl>
          </Grid>

          <Grid item xs={4} container className="">
            <FormControl>
              <Field
                name="po_client"
                label="Po Cliente"
                component={renderTextField}
                disabled={false}
              />
            </FormControl>
          </Grid>

          <Grid item xs={4} container className="">
            <FormControl>
              <Field
                name="oportunity"
                label="Oportunidad"
                component={renderTextField}
                disabled={false}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12} container className="">
          <Grid item xs={4} container className="">
            <Button
              className="form-button"
              variant="contained"
              color="secondary"
              type="submit"
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </form>

      <Grid item xs={12} container className="invoicesDatagrid">
        <DataGrid rows={rows} columns={columns} pageSize={100} />
      </Grid>

      <Grid item xs={12} container className="invoicesDatagrid">
        {" "}
        {message}
        {" "}
      </Grid>
    </Container>
  );
};

FacturaClientes = reduxForm({
  form: "FacturaClientesForm",
  validate,
  requiredFields: [],
  enableReinitialize: true,
})(FacturaClientes);

const mapDispatchToProps = { getFinancesInvoices, refreshLogin };
const mapStateToProps = (state) => ({
  financesInvoices: state.financesInvoices,
  loginData: state.login,
});

export default connect(mapStateToProps, mapDispatchToProps)(FacturaClientes);
