import { FINANCES_INVOICES, FINANCES_INVOICES_ERROR } from '../types'

const initialState = {
  financesInvoices: [],
  loading: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FINANCES_INVOICES:
      return {
        ...state,
        financesInvoices: action.payload,
        loading: true,
      }
    case FINANCES_INVOICES_ERROR:
      return {
        ...state,
        financesInvoices: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
