import {CLEAN_LOGIN, CLEAN_LOGIN_ERROR} from '../types'

export const cleanLogin = () => async dispatch => {

    try{
        dispatch({
            type: CLEAN_LOGIN,
            payload: []
        })
    }
    catch(e){
        dispatch({
            type: CLEAN_LOGIN_ERROR,
            payload: []
        })
    }

}