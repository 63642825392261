const validate = (values, props) => {
    const errors = {}
    const requiredFields = props.requiredFields
    requiredFields.forEach(field => {
      if (!values[field]) {
        console.log('IN', errors);
        errors[field] = 'Required'
      }
    })
    if (
      values.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address'
    }
    console.log('OUT', errors);
    return errors
  }

export default validate;