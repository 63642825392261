import React, { useEffect, useState } from 'react'
import menuOptions from '../data/menuOptions'
import MenuItem from './MenuItem'
import { useRouteMatch } from 'react-router-dom'

const Menu = ({
  menuStatus,
  closeMenu,
}) => {
  const { url } = useRouteMatch()
  
  const [optionsContent, setOptionsContent] = useState([])
  
  useEffect(() => {
    setOptionsContent(menuOptions)
  }, [])
  
  return (
    <ul id="sidenav-left"
        className={'sidenav ' + menuStatus}>
      <li key={2} onClick={closeMenu}>
        <a href="#"
           className="logo-container">Admin
          <i className="material-icons left">spa</i>
        </a>
      </li>
      <li key={3} className="no-padding">
        <ul className="collapsible collapsible-accordion">
          {optionsContent.map((component, key) => (
            <MenuItem key={key}
                      index={key}
                      url={url}
                      data={component}
                      roles={localStorage.getItem('user_data').split(',')}/>
          ))}
        </ul>
      </li>
    </ul>
  )
}

export default Menu
