import {GET_ROLES, ROLES_ERROR} from '../types';
import MiddlewareAction from './MiddlewareAction';

export const getRoles = 
(
    params={ 
        client_id: 0
    }
) => async dispatch => {

    console.log('*rolesAction - params:', params);

    const action = 'rolesAction';
    const serviceMiddleware = new MiddlewareAction({
        action  : action, 
        request : 'get',
        url     : `/roles.json`, 
        type    : {res:GET_ROLES, error:ROLES_ERROR}, 
        data    : params, 
        token   : true
    });

    try{
        await serviceMiddleware.consumeService(false,action).then(()=>{ 

            //let payload = { response:'success' };

            console.log('*rolesAction - serviceMiddleware.apiResponse: ', serviceMiddleware.apiResponse);
                    
            const {data} = (typeof serviceMiddleware.apiResponse.data !== 'undefined') ? serviceMiddleware.apiResponse.data || {data:{}} : {data:{}};

            const responseData = data || {};
            console.log('*rolesAction - try - responseData: ', responseData);

/*
            const {entity_id} = responseData || 0;
            const {entity_name} = responseData || '';
            const {users} = responseData || [];

            const {username} = params || '';
            const {password} = params || '';


            console.log('* entity_id', entity_id);
            console.log('* entity_name', entity_name);
            console.log('* users', users);

            console.log('*username: ', username);
            console.log('*password: ', password);

            users.forEach(element => { 
                if(username===element.email && password===element.password){ 
                    const {info} = element || {};
                    payload.info = info;
                    payload.response = 'success'; 
                } 
            });
*/
            //console.log('*rolesAction - try - else - payload:', payload);
            dispatch({ type: serviceMiddleware.apiResponse.type, payload: responseData });
        });
    }catch(e){ 
        console.log('*rolesAction - catch - serviceMiddleware.apiResponse:', serviceMiddleware.apiResponse);
        dispatch({ type: serviceMiddleware.apiResponse.type, payload: {'response': 'error'}});
    }

}