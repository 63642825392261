import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Navigation from './hooks/Navigation'

import { setLogin } from '../redux/actions/loginAction'
import { cleanLogin } from '../redux/actions/loginCleanAction'
import { getCompany } from '../redux/actions/companyAction'
import { CircularProgress } from '@material-ui/core'

const validate = values => {
  const errors = {}
  
  const requiredFields = [
    'username',
    'password',
  ]
  
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  })
  
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  
  return errors
}

const renderTextField = ({
  label,
  input,
  meta: {
    touched,
    invalid,
    error,
  },
  ...custom
}) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
)

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: '100%',
  },
}))

let Login = props => {
  const classes = useStyles()
  let history   = useHistory()
  
  Navigation(history)
  
  const customClasses = useStyles()
  
  const {
          handleSubmit,
          pristine,
          submitting,
        } = props
  
  const [componentLoaded, setComponentLoaded] = useState(true)
  const [responseMessage, setResponseMessage] = useState()
  const [variant, setVariant]                 = useState('determinate')
  const [progress, setProgress]               = useState(0)
  
  const submitMethod = async e => {
    setResponseMessage('')
    setVariant('indeterminate')
    
    props.cleanLogin().then(() => {
      props.setLogin({
        'email': e.username,
        'password': e.password,
        'company_id': 'w5b0tZ4cA0GTyC179LRn',
      })
    })
  }
  
  const setToken = (res) => {
    window.$token.expires_at = res.expires_at || ''
    window.$token.refreshToken = res.refresh_token || ''
    window.$token.token = res.token || ''
    window.$token.tokenMessage = 'logged'
  }
  
  useEffect(() => {}, [])
  
  useEffect(() => {
    props.cleanLogin()
    props.getCompany()
  }, [componentLoaded])
  
  useEffect(() => {
    const { error } = (typeof props.company !== 'undefined') ? props.company.company || {
      error: {
        context: '',
        message: '',
      },
    } : {
      error: {
        context: '',
        message: '',
      },
    }
  }, [props.company])
  
  useEffect(() => {
    const { error } = (typeof props.loginData !== 'undefined')
      ? props.loginData.login || { error: {} }
      : { error: {} }
    
    const { auth_data } = (typeof props.loginData !== 'undefined')
      ? props.loginData.login || { auth_data: {} }
      : { auth_data: {} }
    
    const { other_menu_links } = (typeof props.loginData !== 'undefined') ? props.loginData.login ||
      { other_menu_links: {} } : { other_menu_links: {} }
    
    const { user_data } = (typeof props.loginData !== 'undefined')
      ? props.loginData.login || { user_data: {} }
      : { user_data: {} }
    
    if (typeof error !== 'undefined') {
      setResponseMessage(error.message || 'Datos de acceso incorrectos')
      setVariant('determinate')
    }
    else {
      if (typeof auth_data !== 'undefined') {
        localStorage.setItem('expires_at', auth_data.expires_at || '')
        localStorage.setItem('refresh_token', auth_data.refresh_token || '')
        localStorage.setItem('token', auth_data.token || '')
        
        setToken(auth_data)
      }
      
      if (typeof other_menu_links !== 'undefined') { /*localStorage.setItem('other_menu_links', other_menu_links);*/ }
      
      if (typeof user_data !== 'undefined') {
        localStorage.setItem('user_data', user_data.permissions || [])
        
        setResponseMessage('Bienvenido')
        
        history.push('/dashboard')
      }
    }
  }, [props.loginData])
  
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <div className="user-logo"/>
        </Avatar>
        
        <Typography component="h1" variant="h5">
          Portal clientes
        </Typography>
        
        <form className={customClasses.form} noValidate onSubmit={handleSubmit(submitMethod)}>
          <Field
            margin="normal"
            autoFocus
            required
            fullWidth
            id="email"
            label="Usuario"
            name="username"
            autoComplete="email"
            component={renderTextField}
          />
          <Field
            margin="normal"
            required
            fullWidth
            label="Contraseña"
            id="password"
            type="password"
            name="password"
            autoComplete="current-password"
            component={renderTextField}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={customClasses.submit}
            disabled={pristine || submitting}
          >
            Ingresar
          </Button>
          
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" style={{
                textAlign: 'center',
                width: '100%',
                display: 'block',
              }}>
                Olvidó contraseña
              </Link>
            </Grid>
          </Grid>
          
          <Grid container>
            <h6 style={{
              textAlign: 'center',
              width: '100%',
            }}>{responseMessage}</h6>
          </Grid>
          
          <Grid container>
            <CircularProgress variant={variant} value={progress}/>
          </Grid>
        </form>
      </div>
    </Container>
  )
}

Login = reduxForm({
  form: 'LoginEditForm',
  validate,
  enableReinitialize: true,
})(Login)

const mapDispatchToProps = {
  setLogin,
  cleanLogin,
  getCompany,
}

const mapStateToProps = (state) => ({
  loginData: state.login,
  company: state.company,
})

export default Login = connect(mapStateToProps, mapDispatchToProps)(Login)
