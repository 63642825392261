import {FINANCES_CREDIT_SUMMARY, FINANCES_CREDIT_SUMMARY_ERROR} from '../types';

const initialState = {
    financesCreditSummary:[],
    loading:false
}

export default function(state = initialState, action){
    //console.log('financesCreditSummaryReducer - action: ', action);
    switch(action.type){
        case FINANCES_CREDIT_SUMMARY:
        return {
            ...state,
            financesCreditSummary: action.payload,
            loading: true
        }
        case FINANCES_CREDIT_SUMMARY_ERROR:
        return {
            ...state,
            financesCreditSummary: action.payload,
            loading: false
        }
        default: return state
    }
}