import {FINANCES_COMPLEMENTS, FINANCES_COMPLEMENTS_ERROR} from '../types';

const initialState = {
    financesComplements:[],
    loading:false
}

export default function(state = initialState, action){
    //console.log('financesComplementsReducer - action: ', action);
    switch(action.type){
        case FINANCES_COMPLEMENTS:
        return {
            ...state,
            financesComplements: action.payload,
            loading: true
        }
        case FINANCES_COMPLEMENTS_ERROR:
        return {
            ...state,
            financesComplements: action.payload,
            loading: false
        }
        default: return state
    }
}