import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({ 
    root: {
        width: '100%',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 200,
        },
    },
    '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
        selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default useStyles;