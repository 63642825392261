import React from 'react'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Layout from './Layout'

import Login from './Login'
import Dashboard from './Dashboard'

import '../assets/App.css'
import '../assets/css/styles.css'

export default function App () {
  return (
    <main>
      <section className="main-layout">
        <Router>
          <div>
            <Switch>
              <Route exact path="/">
                <Layout/>
              </Route>
              <Route path="/login">
                <Login/>
              </Route>
              <Route path="/dashboard">
                <Dashboard/>
              </Route>
            </Switch>
          </div>
        </Router>
      </section>
    </main>
  )
}
