import React, { useState, useEffect } from 'react';
//import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';

import { refreshLogin } from '../redux/actions/loginRefreshAction';

let RefreshToken = props => {
    const [componentLoaded, setComponentLoaded] = useState(true);
    const [responseMessage, setResponseMessage] = useState();

    useEffect(() => {}, []);

    useEffect(() => { 
        console.log('*RefreshToken - useEffect - componentLoaded: ', componentLoaded); 
        componentInitialize();
    }, [componentLoaded]);
    useEffect(()=>{
        console.log('*RefreshToken - useEffect - props.loginData: ', props.loginData);

        const {error} = (typeof props.loginData !== 'undefined') ? props.loginData.login || {error:{}} : {error:{}};
        const {auth_data} = (typeof props.loginData !== 'undefined') ? props.loginData.login || {auth_data:{}} : {auth_data:{}};
        const {other_menu_links} = (typeof props.loginData !== 'undefined') ? props.loginData.login || {other_menu_links:{}} : {other_menu_links:{}};
        const {user_data} = (typeof props.loginData !== 'undefined') ? props.loginData.login || {user_data:{}} : {user_data:{}};
    
        if(typeof error !== 'undefined'){
          setResponseMessage(error.message || 'Error');
          //setVariant('determinate');
        }
        else{
          if(typeof auth_data !== 'undefined'){ 
              /*localStorage.setItem('auth_data', auth_data);*/ 
              localStorage.setItem('expires_at', auth_data.expires_at || '');
              localStorage.setItem('refresh_token', auth_data.refresh_token || '');
              localStorage.setItem('token', auth_data.token || '');
          }
          if(typeof other_menu_links !== 'undefined'){ /*localStorage.setItem('other_menu_links', other_menu_links);*/ }
          if(typeof user_data !== 'undefined'){
            localStorage.setItem('user_data', user_data.permissions || []);
            //localStorage.setItem('myToken', "123");
            setResponseMessage('Bienvenido');
            //history.push("/dashboard");
          }
        }
    }, [props.loginData]);

    const componentInitialize = () => {
        console.log('*RefreshToken - componentInitialize');
        props.refreshLogin({ refresh_token: localStorage.getItem('refresh_token')});
    }

    return ( <div>{responseMessage}</div> );
};

const mapDispatchToProps = { refreshLogin }; //, cleanLogin
const mapStateToProps = (state) => ({ loginData:state.login });
export default RefreshToken = connect(mapStateToProps, mapDispatchToProps)(RefreshToken);
