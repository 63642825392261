import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form';

import loginReducer from './loginReducer';
import menuReducer from './menuReducer';
import rolesReducer from './rolesReducer';
import companyReducer from './companyReducer';
import financesInvoicesReducer from './financesInvoicesReducer';
import financesCreditDetailsReducer from './financesCreditDetailsReducer';
import financesCreditSummaryReducer from './financesCreditSummaryReducer';
import financesComplementsReducer from './financesComplementsReducer';
import financesDownloadPDFReducer from './financesDownloadPDFReducer';
import salesInvoicesReducer from './salesInvoicesReducer'

export default combineReducers({
  login: loginReducer,
  menu: menuReducer,
  roles: rolesReducer,
  company: companyReducer,
  financesInvoices: financesInvoicesReducer,
  financesCreditDetails: financesCreditDetailsReducer,
  financesCreditSummary: financesCreditSummaryReducer,
  financesComplements: financesComplementsReducer,
  financesDownloadPDF: financesDownloadPDFReducer,
  salesInvoices: salesInvoicesReducer,
  form: formReducer
})
