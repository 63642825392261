import React, { useEffect } from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'

import Header from './Header/Header'
import RefreshToken from './RefreshToken'
import CerrarSesion from './CerrarSesion'
import FacturaClientes from './FacturaClientes'
import EstadoCuentaClientes from './EstadoCuentaClientes'
import CreditoCliente from './CreditoCliente'
import FacturaVentas from './FacturaVentas'

import { getMenu } from '../redux/actions/menuAction'
import { getRoles } from '../redux/actions/rolesAction'

let Dashboard = props => {
  const { path } = useRouteMatch()
 
  return (
    <div>
      <section className="">
        <Header/>
        
        <main>
          <Switch>
            <Route exact path={path}/>
            <Route path={`${path}/:topicId`}><Topic/></Route>
          </Switch>
        </main>
        
        <footer className="page-footer"/>
      </section>
    </div>
  )
}

function Topic () {
  let { topicId } = useParams()
  
  switch (topicId) {
    case 'factura-clientes':
      return <FacturaClientes/>
    case 'estado-cuenta-clientes':
      return <EstadoCuentaClientes/>
    case 'credito-cliente':
      return <CreditoCliente/>
    case 'factura-ventas':
      return <FacturaVentas />
    case 'cerrar-sesion':
      return <CerrarSesion/>
    case 'refrescar-sesion':
      return <RefreshToken/>
    default:
      return (
        <div>
          <h3>{topicId}</h3>
        </div>
      )
  }
}

const mapDispatchToProps = {
  getMenu,
  getRoles,
}

const mapStateToProps = (state) => ({
  menuData: state.menu,
  rolesData: state.roles,
  loginData: state.login,
})

export default Dashboard = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard)
