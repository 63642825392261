import {FINANCES_INVOICES, FINANCES_INVOICES_ERROR} from '../types';
import MiddlewareAction from './MiddlewareAction';

export const getFinancesInvoices = ( params={
    date_from: '',
    date_to: '',
    client_id: 0,
    sales_order: '',
    po_client: '',
    oportunity: ''
} ) => async dispatch => {

    console.log('*financesInvoicesAction - params:', params);

    let extra_params = '';

    if(params.sales_order!==''){ extra_params+='&sales_order='+params.sales_order; }
    if(params.po_client!==''){ extra_params+='&po_client='+params.po_client; }
    if(params.oportunity!==''){ extra_params+='&oportunity='+params.oportunity; }


    const action = 'financesInvoicesAction';
    const serviceMiddleware = new MiddlewareAction({
        action  : action, 
        request : 'get',
        url     : `/api/v1/internal/daikin/finances/invoices?date_from=${params.date_from}&date_to=${params.date_to}&client_id=${params.client_id}${extra_params}`, 
        type    : {res:FINANCES_INVOICES, error:FINANCES_INVOICES_ERROR}, 
        data    : params, 
        token   : true
    });

    console.log('*financesInvoicesAction - serviceMiddleware:', serviceMiddleware);

    try{
        await serviceMiddleware.consumeService(false,'backend').then(()=>{ 
            const {data} = serviceMiddleware.apiResponse || {data:[]};
            data.forEach(element => { element['id'] = element.Uuid || 0; });
            console.log('*financesInvoicesAction - try - else - data:', data);
            dispatch({ type: serviceMiddleware.apiResponse.type, payload: data });
        });
    }catch(e){ 
        console.log('*financesInvoicesAction - catch - serviceMiddleware.apiResponse:', serviceMiddleware.apiResponse);
        dispatch({ type: serviceMiddleware.apiResponse.type, payload: {'error': {}}});
    }

}