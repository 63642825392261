import {FINANCES_COMPLEMENTS, FINANCES_COMPLEMENTS_ERROR} from '../types';
import MiddlewareAction from './MiddlewareAction';

export const getFinancesComplements = ( params={
    invoice_id: '2090000054',
    folio_series: 'AD-3000000042',
    module: 'SD'
} ) => async dispatch => {

    console.log('*financesComplementsAction - params:', params);
    // /api/v1/internal/daikin/finances/invoices/2090000054/complements?folio_series=AD-3000000042&module=SD

    const action = 'financesComplementsAction';
    const serviceMiddleware = new MiddlewareAction({
        action  : action, 
        request : 'get',
        url     : `/api/v1/internal/daikin/finances/invoices/${params.invoice_id}/complements?folio_series=${params.folio_series}&module=${params.module}`, 
        type    : {res:FINANCES_COMPLEMENTS, error:FINANCES_COMPLEMENTS_ERROR}, 
        data    : params, 
        token   : true
    });

    console.log('*financesComplementsAction - serviceMiddleware:', serviceMiddleware);

    try{
        await serviceMiddleware.consumeService(false,'backend').then(()=>{ 
            const {data} = serviceMiddleware.apiResponse || {data:[]};
            //data.forEach(element => { element['id'] = element.Uuid || 0; });
            console.log('*financesComplementsAction - try - else - data:', data);
            dispatch({ type: serviceMiddleware.apiResponse.type, payload: data });
        });
    }catch(e){ 
        console.log('*financesComplementsAction - catch - serviceMiddleware.apiResponse:', serviceMiddleware.apiResponse);
        dispatch({ type: serviceMiddleware.apiResponse.type, payload: {'error': {}}});
    }

}