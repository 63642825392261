import 'date-fns'
import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

import { Field } from 'redux-form'
import FormControl from '@material-ui/core/FormControl'
import useStyles from './useStyles'

let FormDatePicker = (props) => {
  const classes                                   = useStyles
  const [componentLoaded, setComponentLoaded]     = useState(true)
  const [value, setValue]                         = useState('')
  const [label, setLabel]                         = useState('label')
  const [labelId, setLabelId]                     = useState('select-outlined')
  const [selectId, setSelectId]                   = useState('select')
  const [menuItems, setMenuItems]                 = useState([])
  const [menuItemClassName, setMenuItemClassName] = useState('dropdown-option')
  const [hidden, setHidden]                       = useState(
    'dropdown-container')
  
  useEffect(() => {}, [])
  
  useEffect(() => {
    initializeComponent()
  }, [componentLoaded])
  
  useEffect(() => {}, [hidden])
  
  useEffect(() => {
    hideDropdown()
  }, [props.hidden])
  
  useEffect(() => {
    const { rows } = props.elements || { rows: [] }
    if (typeof rows !== 'undefined') {
      setMenuItems(rows)
    }
  }, [props.elements])
  
  const initializeComponent = () => {
    if (typeof props.properties !== 'undefined') {
      setLabel(props.properties.label || 'label')
      setLabelId(props.properties.labelId || 'select-outlined')
      setSelectId(props.properties.selectId || 'select')
      setMenuItemClassName(
        props.properties.menuItemClassName || 'dropdown-option',
      )
      
      hideDropdown()
    }
  }
  
  const hideDropdown = () => {
    if (typeof props.hidden !== 'undefined') {
      setHidden(props.hidden ? 'dropdown-container hidden' : 'dropdown-container')
    }
  }
  
  const handleChange = (event) => {
    setValue(event.target.value)
    props.parentCallback(event.target.value)
  }
  
  const DatePickerContainer = ({
    input,
    ...rest
  }) => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <DatePicker
              disableFuture
              openTo="year"
              views={['year', 'month', 'date']}
              // disableToolbar
              // variant="inline"
              format="yyyy/MM/dd"
              margin="normal"
              name={datePickerName}
              id={datePickerId}
              label={datePickerLabel}
              disabled={datePickerDisabled}
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </FormControl>
    )
  }
  
  const [selectedDate, setSelectedDate]             = useState(Date.now())
  const [datePickerType, setDatePickerType]         = useState('none')
  const [datePickerName, setDatePickerName]         = useState('date-picker-name')
  const [datePickerId, setDatePickerId]             = useState('date-picker-id')
  const [datePickerLabel, setDatePickerLabel]       = useState('Fecha')
  const [datePickerDisabled, setDatePickerDisabled] = useState(false)
  //const [componentLoaded, setComponentLoaded] = useState(true);
  
  const [handleDateChangeStatus, setHandleDateChangeStatus] = useState(false)
  
  const handleDateChange = (date) => {
    setHandleDateChangeStatus(true)
    setSelectedDate(date)
  }
  
  useEffect(() => {
    //if(handleDateChangeStatus){
    let newSelectedDate = isNaN(selectedDate)
      ? selectedDate
      : new Date(selectedDate)
    
    props.parentCallback({
      type: datePickerType,
      name: datePickerName,
      value: newSelectedDate,
    })
    setHandleDateChangeStatus(false)
    //}
  }, [selectedDate])
  
  useEffect(() => {
    if (typeof props.parentProps !== 'undefined') {
      setDatePickerType(
        typeof props.parentProps.datePickerType !== 'undefined'
          ? props.parentProps.datePickerType
          : datePickerType,
      )
      setDatePickerName(
        typeof props.parentProps.datePickerName !== 'undefined'
          ? props.parentProps.datePickerName
          : datePickerName,
      )
      setDatePickerId(
        typeof props.parentProps.datePickerId !== 'undefined'
          ? props.parentProps.datePickerId
          : datePickerId,
      )
      setDatePickerLabel(
        typeof props.parentProps.datePickerLabel !== 'undefined'
          ? props.parentProps.datePickerLabel
          : datePickerLabel,
      )
      setDatePickerDisabled(
        typeof props.parentProps.datePickerDisabled !== 'undefined'
          ? props.parentProps.datePickerDisabled
          : datePickerDisabled,
      )
      setSelectedDate(
        typeof props.parentProps.selectedDate !== 'undefined'
          ? props.parentProps.selectedDate
          : selectedDate,
      )
    }
  }, [componentLoaded])
  
  return (
    <Grid item xs={12} className={hidden}>
      <Field
        name={labelId}
        component={DatePickerContainer}
        onChange={handleDateChange}
        labelId={labelId}
        label={label}
        id={selectId}
      />
    </Grid>
  )
}

export default FormDatePicker
