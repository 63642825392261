
// financesDownloadPDFAction.js
// /api/v1/internal/daikin/finances/invoices/2090000054/download/pdf

import {FINANCES_DOWNLOAD_PDF, FINANCES_DOWNLOAD_PDF_ERROR} from '../types';
import MiddlewareAction from './MiddlewareAction';

export const getFinancesDownloadPDF = ( params={
    invoice_id: '2090000054'
} ) => async dispatch => {

    console.log('*financesDownloadPDFAction - params:', params);

    const action = 'financesDownloadPDFAction';
    const serviceMiddleware = new MiddlewareAction({
        action  : action, 
        request : 'get',
        url     : `/api/v1/internal/daikin/finances/invoices/${params.invoice_id}/download/pdf`, 
        type    : {res:FINANCES_DOWNLOAD_PDF, error:FINANCES_DOWNLOAD_PDF_ERROR}, 
        data    : {}, 
        token   : true
    });

    console.log('*financesDownloadPDFAction - serviceMiddleware:', serviceMiddleware);

    try{
        await serviceMiddleware.consumeService(false,'backend').then(()=>{ 
            const {data} = serviceMiddleware.apiResponse || {data:[]};
            //data.forEach(element => { element['id'] = element.Uuid || 0; });
            console.log('*financesDownloadPDFAction - try - else - data:', data);
            dispatch({ type: serviceMiddleware.apiResponse.type, payload: data });
        });
    }catch(e){ 
        console.log('*financesDownloadPDFAction - catch - serviceMiddleware.apiResponse:', serviceMiddleware.apiResponse);
        dispatch({ type: serviceMiddleware.apiResponse.type, payload: {'error': {}}});
    }

}