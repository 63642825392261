import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

let MenuItem = props => {
  const [componentLoaded, setComponentLoaded] = useState(true)
  const [key, setKey]                         = useState(props.index || '')
  const [url, setUrl]                         = useState(props.url || '')
  const [options, setOptions]                 = useState([])
  
  useEffect(() => {
    let allowed_options = []
    const { options }   = props.data || []
    const { roles }     = props || { roles: [] }
    const roles_length  = (typeof roles !== 'undefined' && Array.isArray(roles)) ? roles.length : 0
    
    if (roles_length > 0) {
      options.forEach(element => {
        if (roles.includes(element.role) || element.role === 'static') {
          allowed_options.push(element)
        }
      })
    }
    setOptions(allowed_options)
  }, [componentLoaded])
  
  if (options.length > 0) {
    return (
      <li key={key || 0} className="bold waves-effect"><a className="collapsible-header">{props.data.title || ''}<i
        className="material-icons chevron">chevron_left</i></a>
        <div className="collapsible-body">
          <ul>
            {options.map((component, key) => (<li key={key + component.id}><Link to={`${url}${component.path}`}><i
              className="material-icons">{component.icon}</i>{component.title}</Link></li>))}
          </ul>
        </div>
      </li>
    )
  }
  else { return (<div/>) }
  
}

export default MenuItem
