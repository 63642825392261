export const ACTION_DEFAULT       = 'ACTION_DEFAULT'
export const ACTION_DEFAULT_ERROR = 'ACTION_DEFAULT_ERROR'

export const COMPANY       = 'COMPANY'
export const COMPANY_ERROR = 'COMPANY_ERROR'

export const FINANCES       = 'FINANCES'
export const FINANCES_ERROR = 'FINANCES_ERROR'

export const FINANCES_INVOICES       = 'FINANCES_INVOICES'
export const FINANCES_INVOICES_ERROR = 'FINANCES_INVOICES_ERROR'

export const FINANCES_COMPLEMENTS       = 'FINANCES_COMPLEMENTS'
export const FINANCES_COMPLEMENTS_ERROR = 'FINANCES_COMPLEMENTS_ERROR'

export const FINANCES_CREDIT_DETAILS       = 'FINANCES_CREDIT_DETAILS'
export const FINANCES_CREDIT_DETAILS_ERROR = 'FINANCES_CREDIT_DETAILS_ERROR'

export const FINANCES_CREDIT_SUMMARY       = 'FINANCES_CREDIT_SUMMARY'
export const FINANCES_CREDIT_SUMMARY_ERROR = 'FINANCES_CREDIT_SUMMARY_ERROR'

export const FINANCES_DOWNLOAD_PDF       = 'FINANCES_DOWNLOAD_PDF'
export const FINANCES_DOWNLOAD_PDF_ERROR = 'FINANCES_DOWNLOAD_PDF_ERROR'

export const LOGIN       = 'LOGIN'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const CLEAN_LOGIN       = 'CLEAN_LOGIN'
export const CLEAN_LOGIN_ERROR = 'CLEAN_LOGIN_ERROR'

export const REFRESH_LOGIN       = 'REFRESH_LOGIN'
export const REFRESH_LOGIN_ERROR = 'REFRESH_LOGIN_ERROR'

export const INITIALIZE_LOGIN       = 'INITIALIZE_LOGIN'
export const INITIALIZE_LOGIN_ERROR = 'INITIALIZE_LOGIN_ERROR'

export const GET_MENU   = 'GET_MENU'
export const MENU_ERROR = 'MENU_ERROR'

export const SET_MENU       = 'SET_MENU'
export const SET_MENU_ERROR = 'SET_MENU_ERROR'

export const CLEAN_MENU       = 'CLEAN_MENU'
export const CLEAN_MENU_ERROR = 'CLEAN_MENU_ERROR'

export const INITIALIZE_MENU       = 'INITIALIZE_MENU'
export const INITIALIZE_MENU_ERROR = 'INITIALIZE_MENU_ERROR'

export const GET_ROLES   = 'GET_ROLES'
export const ROLES_ERROR = 'ROLES_ERROR'

export const SET_ROLES       = 'SET_ROLES'
export const SET_ROLES_ERROR = 'SET_ROLES_ERROR'

export const CLEAN_ROLES       = 'CLEAN_ROLES'
export const CLEAN_ROLES_ERROR = 'CLEAN_ROLES_ERROR'

export const INITIALIZE_ROLES       = 'INITIALIZE_ROLES'
export const INITIALIZE_ROLES_ERROR = 'INITIALIZE_ROLES_ERROR'

export const SALES_INVOICES       = 'SALES_INVOICES'
export const SALES_INVOICES_ERROR = 'SALES_INVOICES_ERROR'
